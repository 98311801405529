import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
//required to install: npm install --save ts-md5
import {Md5} from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { SysconfService } from './sysconf.service';

import {
  CashHistoryResponse
} from '../services/interface/responses';

export const API = {
  endpoint:'/en/listCashHistory'
};

@Injectable({
  providedIn: 'root'
})
export class CashlistService {

  constructor(
    private http: HttpClient,
    private sysconf: SysconfService
  ) { }


  getList(jwt: string, pageNum: number, order: string, rows: number, betId = ''){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+jwt}`).end();
    return this.http.post<CashHistoryResponse>(
      `${this.sysconf.gatewayDomain+API.endpoint}`,
      {
        token: `${jwt}`,
        page: `${pageNum}`,
        order: `${order}`,
        rows: `${rows}`,
        betId: `${betId}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      });

  }



}
