import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
//required to install: npm install --save ts-md5
import {Md5} from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { SysconfService } from './sysconf.service';

import {
  StartShiftResponse,AddCashResponse, PayoutResponsev2, RefundResponsev2, Bayad
} from '../services/interface/responses';

export const START_SHIFT_API = {
  endpoint:'/en/StartShift'
};

export const ADD_CASH_API = {
  endpoint:'/en/AddCash'
};

export const REMOVE_CASH_API = {
  endpoint:'/en/RemoveCash'
};

export const REMOVE_CASH_WIN_API = {
  endpoint:'/en/RemoveCashWin'
};

export const REMOVE_CASH_REFUND_API = {
  endpoint:'/en/RemoveCashRefund'
};

export const END_SHIFT_API = {
  endpoint:'/en/EndShift'
};

export const CHECK_SHIFT_API = {
  endpoint:'/en/CheckShift'
};

@Injectable({
  providedIn: 'root'
})
export class StartshiftService {

  constructor(
    private http: HttpClient,
    private sysconf: SysconfService
  ) { }

  start(jwt: string, amount: string, notes: string, oneTimeUseCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+amount+notes+jwt}`).end();
    return this.http.post<StartShiftResponse>(
      `${this.sysconf.gatewayDomain+START_SHIFT_API.endpoint}`,
      {
        oneTimeUseCode: `${oneTimeUseCode}`,
        amount: `${amount}`,
        notes: `${notes}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }


    add(jwt: string, amount: string, notes: string, oneTimeUseCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+amount+notes+jwt}`).end();
    return this.http.post<AddCashResponse>(
      `${this.sysconf.gatewayDomain+ADD_CASH_API.endpoint}`,
      {
        oneTimeUseCode: `${oneTimeUseCode}`,
        amount: `${amount}`,
        notes: `${notes}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

  remove(jwt: string, amount: string, notes: string, oneTimeUseCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+amount+notes+jwt}`).end();
    return this.http.post<AddCashResponse>(
      `${this.sysconf.gatewayDomain+REMOVE_CASH_API.endpoint}`,
      {
        oneTimeUseCode: `${oneTimeUseCode}`,
        amount: `${amount}`,
        notes: `${notes}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

  remove4Win(jwt: string, ticketCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+ticketCode+jwt}`).end();
    return this.http.post<Bayad>(
      `${this.sysconf.gatewayDomain+REMOVE_CASH_WIN_API.endpoint}`,
      {
        ticketCode: `${ticketCode}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

  remove4Refund(jwt: string, ticketCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+ticketCode+jwt}`).end();
    return this.http.post<Bayad>(
      `${this.sysconf.gatewayDomain+REMOVE_CASH_REFUND_API.endpoint}`,
      {
        ticketCode: `${ticketCode}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

  /*
  params: new HttpParams().set('appId', appId),
  headers: new HttpHeaders().set('Authorization', hashStr.toString())
  */

  end(jwt: string, amount: string, notes: string, oneTimeUseCode: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+amount+notes+jwt}`).end();
    return this.http.post<StartShiftResponse>(
      `${this.sysconf.gatewayDomain+END_SHIFT_API.endpoint}`,
      {
        oneTimeUseCode: `${oneTimeUseCode}`,
        amount: `${amount}`,
        notes: `${notes}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

  check(jwt: string, amount: string, notes: string){

    const appId = this.sysconf.appId;
    const timestamp = Date.now();
    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+amount+notes+jwt}`).end();
    return this.http.post<StartShiftResponse>(
      `${this.sysconf.gatewayDomain+CHECK_SHIFT_API.endpoint}`,
      {
        amount: `${amount}`,
        notes: `${notes}`,
        token: `${jwt}`,
        appId:`${appId}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      );

  }

}
