import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
//required to install: npm install --save ts-md5
import {Md5} from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { SysconfService } from './sysconf.service';


import {
  CheckTokenResponse
 } from '../services/interface/responses';


 //https://gateway.sabongexpress.com/en/auth
 //endpoint:'https://sabongexpress.com/en/pos/login'
 // endpoint:'https://gateway.sabongexpress.com/en/auth'


//  endpoint:'https://gateway.sabongexpress.com/en/auth/checkToken'
export const CHECK_TOKEN_API = {
  endpoint:'/en/CheckToken/'
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  //credentials: LoginResponse;

  constructor(
    private http: HttpClient,
    private sysconf: SysconfService
  ) { }



  // 'Authorization': 'Bearer ' + localStorage.getItem("token")

  checkToken(jwt: string){

      const appId = this.sysconf.appId;
      const timestamp = Date.now();
      const md5 = new Md5();
      const hashStr = md5.appendStr(`${appId+jwt}`).end();
      return this.http.post<CheckTokenResponse>(
        `${this.sysconf.gatewayDomain+CHECK_TOKEN_API.endpoint}`,
        {
          token: `${jwt}`,
          appId:`${appId}`,
          hash:hashStr.toString(),
          timestamp:timestamp.toString()
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
        }
        );

  }

}
